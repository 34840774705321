export default function ProductsView(){


    return(
    <>
        <h1>
            la vista de productos
        </h1>
    </>
    );
}