export default function ServiciosView(){




    return(

        <>
            <h1>servicios</h1>
        </>
    )
}