export default function HomeBtn(){



    return(
        <>
            <div className="custom-btn-home">
                    Ir a la seccion
            </div>
        </>
    )

}